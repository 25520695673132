import { useDepositForm } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { UserPosition, vaultVersion, VaultVersion } from '@apps-orangefi/lib/types'
import { DistributorList } from '@apps-orangefi/lib/utils'
import { DepositForm, WalletProps } from '@apps-orangefi/ui/molecules/strategy'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { useDepositLPDfi } from '@apps-orangefi/wagmi/hooks'
import { useAccount } from 'wagmi'

export type DepositContainerProps = {
  vaultAddress: AddressType | undefined
  tokenBalance: BN | undefined
  tokenSymbol: string | undefined
  userPosition: UserPosition | undefined
  vaultCapacity: {
    totalDeposit: BN
    maxCapacity: BN
  }

  minDepositAssets: BN
  depositTokenAddress: AddressType | undefined
  distributorWhiteList: DistributorList
  vaultVersion: VaultVersion
}

type Props = { deposit: DepositContainerProps; wallet: WalletProps }

export const DepositContainer = ({ deposit, wallet }: Props) => {
  const { address: account, isConnected } = useAccount()

  const protcolFee =
    deposit.vaultVersion === vaultVersion.LPDfi
      ? { deposit: 0.1, performance: 0 }
      : { deposit: 0, performance: 10 }

  const {
    amount: depositAmount,
    setAmount: onSetDepositAmount,
    onDeposit,
    onSetMaxBalance,
    isAllowedWallet,
  } = useDepositForm(
    deposit.depositTokenAddress,
    deposit.vaultAddress,
    deposit.vaultAddress,
    account,
    deposit.tokenBalance,
    deposit.distributorWhiteList,
    useDepositLPDfi,
    MODAL_TYPES.TxModal,
    deposit.vaultVersion
  )

  const depositProps = {
    depositAmount,
    minDepositAmount: deposit.minDepositAssets,
    depositCap: deposit.vaultCapacity.maxCapacity,
    tokenBalance: deposit.tokenBalance,
    remainingCapacity: deposit.vaultCapacity.maxCapacity.minus(deposit.vaultCapacity.totalDeposit),
    onSetDepositAmount,
    onDeposit,
    onSetMaxBalance,
    isShowDepositCap: false,
    protcolFee,
    tokenSymbol: deposit.tokenSymbol,
  }

  return <DepositForm deposit={depositProps} wallet={wallet} />
}
