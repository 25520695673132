'use client'

import { FallbackProps } from 'react-error-boundary'

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div className="container px-8 py-16 h-full">
      <div className="flex flex-col h-full justify-center items-center type-lg-medium">
        {error.message}
      </div>
    </div>
  )
}
