import { useMigrateAutomatorForm } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { DistributorList } from '@apps-orangefi/lib/utils'
import { MigrateForm } from '@apps-orangefi/ui/molecules/lpdfi'
import { useAccount } from 'wagmi'

export type LPDFiMigrationFormProps = {
  tokenAddress: AddressType | undefined
  fromWithdrawAddress: AddressType | undefined
  toDepositAddress: AddressType | undefined
  handlerAddress: AddressType
  lpBalance: BN | undefined
  accountTokenBalance: BN | undefined
  whiteList: DistributorList
  onConnect: () => void
  onSwitchChainModal: () => void
  isConnected: boolean
  isActiveChainSupported: boolean
}

export const LPDFiMigrationForm = ({
  tokenAddress,
  fromWithdrawAddress,
  toDepositAddress,
  handlerAddress,
  lpBalance,
  accountTokenBalance,
  whiteList,
  onConnect,
  onSwitchChainModal,
  isConnected,
  isActiveChainSupported,
}: LPDFiMigrationFormProps) => {
  const { address: account } = useAccount()
  const { amount, onMigrate, allowance } = useMigrateAutomatorForm(
    tokenAddress,
    fromWithdrawAddress,
    toDepositAddress,
    account,
    lpBalance,
    accountTokenBalance,
    whiteList,
    handlerAddress
  )

  return (
    <MigrateForm
      onSubmit={onMigrate}
      onConnect={onConnect}
      onSwitchChainModal={onSwitchChainModal}
      isConnected={isConnected}
      isActiveChainSupported={isActiveChainSupported}
      isEnabled={lpBalance?.gt(0) ?? false}
    />
  )
}
