import { useModal } from '@apps-orangefi/hooks'
import { StrykeLP } from '@apps-orangefi/lib/types'
import { ReserveCard } from '@apps-orangefi/ui/molecules/cards'
import { useIsApporvedStrykeLP, useReserveHelpers } from '@apps-orangefi/wagmi/hooks'
import { MODAL_TYPES } from '../modals'
import { useCallback, useMemo } from 'react'

type Props = StrykeLP & {
  account: AddressType | undefined
  refetch: () => void
  className?: string
}

export const ReserveCardContainer = ({
  account,
  title,
  baseTokenSymbol,
  quoteTokenSymbol,
  amm,
  derivative,
  chain,
  reserveProxyAddress,
  handlerAddress,
  lps,
  tokenTotalLiquidity,
  totalAmountUSD,
  refetch,
  className,
}: Props) => {
  const { reserveHelperAddress, refetch: refetchReserveHelper } = useReserveHelpers(
    reserveProxyAddress,
    account
  )

  const {
    isApproved,
    isFetched,
    refresh: refreshIsApproved,
  } = useIsApporvedStrykeLP(handlerAddress, account, reserveHelperAddress)
  const { showModal, hideModal } = useModal()

  const onSubmit = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.ReserveAllLPModalContainer,
      modalProps: {
        title: isApproved ? 'Reserve Liquidity transaction' : 'Approve Stryke LP transaction',
        reserveProxyAddress,
        handlerAddress,
        lps,
        isApproved,
        handleClose: () => {
          hideModal()
          refetch()
        },
      },
    })
  }, [reserveProxyAddress, handlerAddress, lps, isApproved])

  const buttonText = useMemo(() => {
    if (lps.length === 0) {
      return 'No LP'
    }
    if (isApproved) {
      return 'Reserve'
    }
    return 'Approve'
  }, [isApproved, lps])

  return (
    <ReserveCard
      title={title}
      baseTokenSymbol={baseTokenSymbol}
      quoteTokenSymbol={quoteTokenSymbol}
      amm={amm}
      derivative={derivative}
      totalAmountUSD={totalAmountUSD}
      tokenTotalLiquidity={tokenTotalLiquidity}
      buttonLabel={buttonText}
      onSubmit={onSubmit}
      className={className}
    />
  )
}
