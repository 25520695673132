import { useWithdrawReservedLPForm } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import {
  TotalWithdrawableReserveLiquidity,
  WithdrawableReserveLiquidity,
} from '@apps-orangefi/lib/types'
import { RedeemReservedLiquidity } from '@apps-orangefi/ui/molecules/lpdfi'
import { WalletProps } from '@apps-orangefi/ui/molecules/strategy'
import { useEffect } from 'react'
import { UseQueryExecute } from 'urql'

export type RedeemReservedLiquidityContainerProps = {
  reserveProxyAddress: AddressType | undefined
  handlerAddress: AddressType | undefined
  chainId: number
  reservedLPStatus: {
    tokenIds: string[]
    fetching: boolean
    refetch: UseQueryExecute
    withdrawableReserveLiquidities: WithdrawableReserveLiquidity[]
    totalWithdrawableReserveLiquidity: TotalWithdrawableReserveLiquidity
    totalAmountUSD: BN
  }
}

type Props = {
  redeemReservedLP: RedeemReservedLiquidityContainerProps
  wallet: WalletProps
  className?: string
}

export const RedeemReservedLiquidityContainer = ({
  redeemReservedLP,
  wallet,
  className,
}: Props) => {
  const { onWithdrawReservedLP } = useWithdrawReservedLPForm(
    redeemReservedLP.reserveProxyAddress,
    redeemReservedLP.handlerAddress,
    redeemReservedLP.reservedLPStatus.tokenIds,
    redeemReservedLP.reservedLPStatus.refetch
  )

  useEffect(() => {
    redeemReservedLP.reservedLPStatus.refetch()
  }, [])
  // }, [redeemReservedLP.reservedLPStatus.refetch])

  return (
    <div className={className}>
      <RedeemReservedLiquidity
        reservedLPStatus={redeemReservedLP.reservedLPStatus}
        onWithdrawReservedLP={onWithdrawReservedLP}
        {...wallet}
      />
    </div>
  )
}
