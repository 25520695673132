import { useAlphaProductInfo } from '@apps-orangefi/hooks'
import { ProductContractInfo } from '@apps-orangefi/lib/types'
import { ProductCard } from '@apps-orangefi/ui/molecules/cards'
import { useAccount } from 'wagmi'

type Props = {
  contractsInfo: ProductContractInfo
  className?: string
}

export const AlphaProductCardContainer = ({ contractsInfo, className }: Props) => {
  const { address: account } = useAccount()
  const { product } = useAlphaProductInfo(contractsInfo, account)

  return <ProductCard {...product} className={className} />
}
